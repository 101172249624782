#cy {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  margin: 0 auto;
  display: flex;
  background-color: #f8f9fa;
}

.node {
  font-size: 12px;
  text-align: center;
  color: #000;
}

.edge {
  width: 2px;
  line-color: #ccc;
}

.geneRatio {
  background-color: #e8e406;
}

.protein {
  background-color: #0074d9;
}

.highlighted {
  background-color: yellow !important;
  border-color: red !important;
  border-width: 2px !important;
  transition: background-color 0.5s, border-color 0.5s;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 60%;
  box-sizing: border-box;
  font-size: 14px;
}

.search-button {
  padding: 10px 20px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffdddd;
  border-left: 6px solid #f44336;
  color: #d8000c;
  border-radius: 4px;
  font-size: 14px;
}
