.filter__container {
  /*   width: inherit; */
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background: #f2f5f7;
  padding-left: 20px;
}

.filter__row {
  width: inherit;
  /*   min-height: 50px; */
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #e5e5e5;
}

.filter__row:last-child {
  border: none;
}

/* KEYWORD FILTER */

.filter__item {
  /*   max-width: 210px; */
  position: relative;
}

.filter__item input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.filter__item .label svg {
  position: absolute;
  right: 10px;
  top: 6px;
  stroke: #bababa;
}

.label {
  height: 28px;
  padding: 8px 8px 8px 0;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.4px;
  width: 100%;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  color: #696969;
  /* background: #f6f8fa;  */
}

input[type="checkbox"]:checked ~ label svg {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

input[type="checkbox"]:checked ~ .container {
  overflow: normal;
  height: auto;
  padding: 8px 8px 8px 0;
  /*   border-top: 1px solid #e1e4e8; */
}

input[type="checkbox"]:checked ~ .price__container {
  padding: 25px 18px;
}

.keyword__search {
  padding: 5px;
  font-size: 13px;
  width: 100%;
  border: 1px solid #cbcccc;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppBox {
  width: 1100px;
  margin-left: 250px;
  margin-top: 10px;
}

.AppBox1 {
  margin-top: 10px;
  margin-left: 250px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.example-header {
  text-align: left;
}
.App-link {
  color: #61dafb;
}

#page-size {
  margin-left: 5px;
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text {
  color: black;
  text-align: center;
}

.ag-cell-wrap-text {
  white-space: nowrap;
  word-break: break-word;
}

.ag-theme-alpine {
  --ag-header-height: 50px;
}

.download-link {
  position: relative;
}

.download-hover-content {
  position: absolute;
  bottom: -10px; /* Adjust the distance from the image */
  left: 70%;
  transform: translateX(-50%);
  text-align: center;
}

.download-hover-image {
  width: 40px; /* Adjust the width of the image */
  height: 70%;
  margin-right: 10px;
}
