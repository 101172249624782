.custom-tooltip {
  /* background-image: linear-gradient(to right, red, yellow); */
  /* color: red; */
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  position: relative;
}
.custom-tooltip:before {
  /* background-image: linear-gradient(to right, red, yellow); */
  position: absolute;
  top: -10px;
  left: 5px;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid rgba(20, 20, 20, 0.8);
}

#conicValue {
  /* background: conic-gradient(yellow, orange); */
  background: conic-gradient(
    from 90deg,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red,
    violet
  );
}

/* .conic {
     background: conic-gradient(yellow, orange);
    background: conic-gradient(
      from 90deg,
      violet,
      indigo,
      blue,
      green,
      yellow,
      orange,
      red,
      violet
    );
  } */

.App {
  font-family: sans-serif;
  text-align: center;
  width: 45%;
  float: right;
}

#coneG {
  background: conic-gradient(
    from 90deg,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red,
    violet
  );
}

.header_cell {
  background-color: #1463b9;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid white;
}

div p {
  color: black;
  font: Lato;
  font-size: 14px;
}

.label,
.desc {
  color: white;
}

.gradient {
  background-image: linear-gradient(
    to right,
    red,
    yellow
  ); /* Standard syntax (must be last) */
}

.a123 {
  background-image: linear-gradient(
    to right,
    red,
    yellow
  ); /* Standard syntax (must be last) */
}

.column__left,
.column__right {
  display: flex;
  flex-basis: auto;
  /*   border: 1px solid red; */
}

.column__right {
  flex-direction: column;
  flex-grow: 1;
}

@media (min-width: 670px) {
  .column__left {
    flex-direction: column;
    width: 100px;
    min-width: 100px;
  }
  .column__right {
    margin-left: 20px;
  }
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 240px;
  background-color: #f9f8f7;
  position: absolute;
  height: 800px;
  overflow-y: scroll;
  overflow: scroll;
}

.sidebar1 {
  margin: 0;
  padding: 0;
  width: 240px;
  background-color: #f9f8f7;
  position: absolute;
  height: 37rem;
  overflow-y: scroll;
  overflow: scroll;
}

.sidebar_button {
  color: #959595;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%; /* 35.2px */
  text-transform: uppercase;
}

.head_background {
  /* height: 40%; */
  padding: 35px;
  background-position: center;
  background-size: cover;
}

.head_title {
  color: white;
  display: left;
  margin-bottom: 1rem;
}

.head_text {
  color: #fff;

  /* Page Body Text */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-bottom: 15px;
}

#hero-section {
  background-color: #f9f8f7;
  height: 3em;
  display: flex;
}

#hero-text {
  font-weight: 600;
  font-family: Lato;
  font-size: 17px;
}

.hero-link {
  font-family: Montserrat;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  color: #226ab3;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: lowercase;
}

.item {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
}

.menu > li {
  margin: 0;

  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.rowC {
  display: flex;
  flex-direction: row;
  position: relative;
}

.charts {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 600px;
  padding: 20px;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  margin-left: 20%;
  padding: 0;
  list-style: none;
}

.charts1 {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 645px;
  padding: 20px;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto !important;
  height: 100%;
}

.accordion {
  max-width: 1000px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #f8f8f8;
}

/* unvisited link */
.linksa {
  color: lightgray;
}

/* visited link */
.linksa:visited {
  color: lightgreen;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.table-cell {
  stroke: black;
  display: inline;
  position: relative;
  vertical-align: middle;
  display: inline-block;
}

.svg-text {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

a .download-link {
  display: none;
}
