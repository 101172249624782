/*
  Variables used to control the fixed width and padding of the application.
*/
:root {
  --fixed-width: 1140px;
  --fixed-padding: 24px;
  --header-height: 75px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  overflow-x: hidden;
  max-width: 100%;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

#application,
#page-content {
  position: relative;
}

/* Styles enabling the fixed width view 
#application[data-layout="fixed"] header > div,
#application[data-layout="fixed"] > #page-content,
#application[data-layout="fixed"] > #footer .footer-top {
  width: var(--fixed-width);
  margin-left: auto;
  margin-right: auto;
}
*/
#application[data-layout="fixed"] .css-onape-MuiStack-root {
  margin-left: 0px;
}
/* Main page content wrapper and footer.  Applying the same padding as the header */
#page-content,
#footer .footer-top {
  padding-left: var(--fixed-padding);
  padding-right: var(--fixed-padding);
}
#page-content {
  padding-top: var(--header-height);
  margin-bottom: 2rem;
  background-color: #f9f8f7;
}

/* Overriding default padding with CSS variable for easier editing */
@media (min-width: 600px) {
  #root .css-1yo9slo-MuiToolbar-root {
    padding: 0px var(--fixed-padding);
  }
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  height: var(--header-height);
}

header.css-v526sl-MuiPaper-root-MuiAppBar-root {
  position: fixed;
  z-index: 10;
  background-color: white;
  box-shadow: none;
  transition: none;
}
body.scrolled header.css-v526sl-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0 1px 11px 0px #666;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure it fills the entire viewport */
  overflow: hidden; /* Prevent scrolling on the wrapper itself */
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1; /* This will allow the content to expand and push the footer down */
  overflow-y: auto; /* Keep scrolling enabled for content */
  min-height: 0; /* Important for flexbox to allow children to shrink correctly */
}

.footer-container {
  margin-top: auto;
  width: 100%;
}

#footer {
  background: #fff;
  font-size: 14px;
  /* box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08); */
  padding-bottom: 3rem;
  height: 350px;
  margin-bottom: 3%;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #124265;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #2487ce;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #2487ce;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  border: 1px solid #cde5f6;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -2px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #2487ce;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #3194db;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2487ce;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #3194db;
  color: #fff;
  text-decoration: none;
}

#footer-logos {
  text-align: center;
  margin-bottom: 1.5rem;
}

#footer-logos ul {
  list-style-type: none;
  padding: 0;
  margin-left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container {
  margin-left: 5%;
  margin-right: 5%;
}

#footer-logos li,
#footer-logos li a {
  display: inline-block;
}

#footer-text {
  font-size: medium;
  color: #3a3a3a;
}

#footer-text a {
  color: #6c6c6c;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

#footer-text ul {
  list-style-type: none;
  margin: 10px;
}

#footer-text li {
  border-right: 1px solid #999999;
  display: inline;
  margin: 0;
  padding: 0 0.5em;
}

#last_sentence {
  font-size: medium;
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: center;
}
#footer-text li.last {
  border-right: medium none;
  margin-bottom: 35px;
}

.address {
  color: rgba(90, 90, 90, 0.6);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border-right: none;
  letter-spacing: 0.5px;
}
.toolTip {
  position: fixed;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.first_li {
  margin-right: 65px;
}

.middle {
  margin-left: 65px;
  margin-right: 65px;
}

.last_li {
  margin-left: 65px;
  border-right: none;
  display: inline;
  margin: 0;
  padding: 0 0.5em;
}
