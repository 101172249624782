.graphContainer {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

svg {
  display: block;
  margin: 0 auto;
}

.axis path,
.axis line {
  fill: none;
  stroke: #333;
  shape-rendering: crispEdges;
}

.axis text {
  font-size: 12px;
  font-weight: bold;
  fill: #333;
}

.circle {
  fill: #1800b6;
  opacity: 1;
  stroke: #2c3e50;
  stroke-width: 1px;
}

.circle:hover {
  cursor: pointer;
}

.line {
  stroke: #ccc;
  stroke-dasharray: 4 2;
}

.borderLine {
  stroke: #333;
  stroke-width: 1px;
}

.tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 8px;
  font: 14px sans-serif;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: 0px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.axisLabel {
  font-size: 14px;
  fill: #333;
  font-weight: bold;
  margin-top: 10px;
}
